import Vue from "vue";
import { ToastPlugin, ModalPlugin } from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";

import i18n from "@/libs/i18n";
import socketIo from 'socket.io-client';
import VueSocketIO from "vue-socket.io";
import router from "./router";
import store from "./store";
import App from "./App.vue";

// Global Components
import "./global-components";

// 3rd party plugins
import "@axios";
import "@/libs/acl";
import "@/libs/portal-vue";
import "@/libs/clipboard";
import "@/libs/toastification";
import "@/libs/sweet-alerts";
import "@/libs/vue-select";
import "@/libs/tour";
import firebaseMessaging from './firebase';

// Axios Mock Adapter
// import "@/@fake-db/db";

Vue.prototype.$messaging = firebaseMessaging;

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

// Composition API
Vue.use(VueCompositionAPI);

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require("@core/assets/fonts/feather/iconfont.css"); // For form-wizard

// import core styles
require("@core/scss/core.scss");

// import assets styles
require("@/assets/scss/style.scss");

Vue.config.productionTip = false;

// Vue.use(new VueSocketIO({ connection: socketIo(process.env.VUE_APP_API_URL, { autoConnect: true, auth: { token: localStorage.getItem('accessToken') }, transports: ['websocket'] }, store) }));

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount("#app");
