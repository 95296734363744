import useJwt from "@/auth/jwt/useJwt";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getReportFinanceByYear(ctx, params) {
      return useJwt.getReportFinanceByYear(params).then(response => response);
    },
    getReportPropertyGeneral(ctx, params) {
      return useJwt.getReportPropertyGeneral(params).then(response => response);
    },
    getReportPropertyDetail(ctx, params) {
      return useJwt.getReportPropertyDetail(params).then(response => response);
    },
    getReportPropertyCounting(ctx, params) {
      return useJwt.getReportPropertyCounting(params).then(response => response);
    },
    getReportFinanceCashFlow(ctx, params) {
      return useJwt.getReportFinanceCashFlow(params).then(response => response);
    },
    getReportFinanceCounting(ctx, params) {
      return useJwt.getReportFinanceCounting(params).then(response => response);
    },
    getReportFinanceDeposit(ctx, params) {
      return useJwt.getReportFinanceDeposit(params).then(response => response);
    },
    exportReportFinanceDeposit(ctx, params) {
      return useJwt.exportReportFinanceDeposit(params).then(response => response);
    },
    getReportFinanceDepositTingTong(ctx, params) {
      return useJwt.getReportFinanceDepositTingTong(params).then(response => response);
    },
    exportReportFinanceDepositTingTong(ctx, params) {
      return useJwt.exportReportFinanceDepositTingTong(params).then(response => response);
    },
    getReportCancelDeposit(ctx, params) {
      return useJwt.getReportCancelDeposit(params).then(response => response);
    },
    exportReportCancelDeposit(ctx, params) {
      return useJwt.exportReportCancelDeposit(params).then(response => response);
    },
    getReportDebtRecovery(ctx, params) {
      return useJwt.getReportDebtRecovery(params).then(response => response);
    },
    exportReportDebtRecovery(ctx, params) {
      return useJwt.exportReportDebtRecovery(params).then(response => response);
    },
    getReportCheckTenant(ctx, params) {
      return useJwt.getReportCheckTenant(params).then(response => response);
    },
    exportReportCheckTenant(ctx, params) {
      return useJwt.exportReportCheckTenant(params).then(response => response);
    },
    getReportCheckInvoice(ctx, params) {
      return useJwt.getReportCheckInvoice(params).then(response => response);
    },
    exportReportCheckInvoice(ctx, params) {
      return useJwt.exportReportCheckInvoice(params).then(response => response);
    },
    getReportCheckEW(ctx, params) {
      return useJwt.getReportCheckEW(params).then(response => response);
    },
    exportReportCheckEW(ctx, params) {
      return useJwt.exportReportCheckEW(params).then(response => response);
    },
    getReportCheckFee(ctx, params) {
      return useJwt.getReportCheckFee(params).then(response => response);
    },
    exportReportCheckFee(ctx, params) {
      return useJwt.exportReportCheckFee(params).then(response => response);
    },
    exportReportBillingCalendar(ctx, params) {
      return useJwt.exportReportBillingCalendar(params).then(response => response);
    },
    getAnalyticsReportFinanceByMonth(ctx, params) {
      return useJwt.getAnalyticsReportFinanceByMonth(params).then(response => response);
    },
    exportAnalyticsReportFinanceByMonth(ctx, params) {
      return useJwt.exportAnalyticsReportFinanceByMonth(params).then(response => response);
    },
    getReportFinancePrepaid(ctx, params) {
      return useJwt.getReportFinancePrepaid(params).then(response => response);
    },
    getReportFinanceByMonth(ctx, params) {
      return useJwt.getReportFinanceByMonth(params).then(response => response);
    },
    getReportDebt(ctx, params) {
      return useJwt.getReportFinanceDebt(params).then(response => response);
    },
    getReportBillingCalendar(ctx, params) {
      return useJwt.getReportBillingCalendar(params).then(response => response);
    },
    exportReportDebt(ctx, params) {
      return useJwt.exportReportFinanceDebt(params).then(response => response);
    },
    getReportNewDebt(ctx, params) {
      return useJwt.getReportNewDebt(params).then(response => response);
    },
    exportReportNewDebt(ctx, params) {
      return useJwt.exportReportNewDebt(params).then(response => response);
    },
    resyncAllocation(ctx, data) {
      return useJwt.resyncAllocation(data).then(response => response);
    }
  },
};
